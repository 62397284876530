import React from 'react';
import "../Varios/Varios.scss";
import Cabecera from "../Cabecera";
import Otros from "../Otros";
import imgvarios from "../../img/varios.svg";
const Varios=({varios})=> {
 
  return (
    <section className="section varios" id="varios">
      <Cabecera titulo="Misc" img={imgvarios} descripcion={varios.descripcion}/>
      <div className="fondo_varios">
        <div className="contenedor_varios">
          <Otros otros={varios.items}/>
        </div> 
      </div>
    </section>
  );
}
export default Varios;