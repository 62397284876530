import React, { useCallback } from "react";
import { useState, useEffect } from "react";
import "../SegundaCarga/SegundaCarga.scss";
import Articulo from "../Articulo";
import Paginacion from "../Paginacion";

const SegundaCarga = ({ ordenado }) => {
  const [guardaFecha, setGuardaFecha] = useState([]);
  const [filtrado, setFiltrado] = useState([]);
  const [entra, setEntra] = useState(false);

  useEffect(() => {
    let temp = [];
    ordenado.map((item) => {
      const anno = item.fecha.substr(0, 4);
      if (!temp.includes(anno)) {
        temp.push(anno);
      }
      return
    });
    if (temp[0]) {
      Filtrar(temp[0])
    }
    setGuardaFecha(temp);
  }, [ordenado]);

  useEffect(() => {
  }, [filtrado]);

  const Filtrar = (fecha) => {
    const guarda = ordenado.filter((item) => item.fecha.substr(0, 4) === fecha);
    setFiltrado(guarda);
    setEntra(true);
  };

  return (
    <div className="contenedor_autobombo segundo">
      <ul className="filtro">
        {guardaFecha.map((itemdos, index) => (
          <li key={index} className="filtro_item" onClick={() => Filtrar(itemdos)}>
            {itemdos}
          </li>
        ))}
      </ul>
      <div className="contenedor_autobombo segundo">
        { entra &&
          filtrado.map((element, index) => (
          <Articulo key={index} item={element} />
          ))
        }
      </div>
    </div>
  );
};
export default SegundaCarga;
