import React from 'react';
import "../Autobombo/Autobombo.scss";
import Cabecera from "../Cabecera/index";
import imgautobombo from '../../img/autobombo.svg';
import Cautobombo from '../Cautobombo';
const Autobombo=({autobombo})=> {
  return (
    <>
      <section className="section autobombo" id="autobombo">
        <div className="degradado">
          <Cabecera titulo="Self-Praise" img={imgautobombo} descripcion={autobombo.descripcion} />
        </div>  
          <Cautobombo dat_autobombo={autobombo.items}/> 
      </section>
    </>
  );
}
export default Autobombo;