import React from 'react';
import "./Navegador.scss";
import caricatura from '../../img/caricatura.png';
import autobombo from '../../img/autobombo.svg';
import proyectos from '../../img/proyectos.svg';
import varios from '../../img/varios.svg';
const Navegador=({menu1, menu2, menu3})=> {
  return (
  <section className="section navegador" id="menu">
    <div className="cabecera" >
      <img src={caricatura} alt=""/>
      <span>Anastasia Kondratieva</span>
    </div>
    <div className="bloque_menu"> 
      <nav>
        <ul className="menu">
          <li className="menu_item"><a href="#autobombo" className="menu_item_enlace"><img src={autobombo} alt=""/>{menu1}</a></li> 
          <li className="menu_item"><a href="#proyectos" className="menu_item_enlace"><img src={proyectos} alt=""/>{menu2}</a></li> 
          <li className="menu_item"><a href="#varios" className="menu_item_enlace"><img src={varios}  alt=""/>{menu3}</a></li> 
        </ul>
      </nav>
    </div>
  </section>);
}
export default Navegador;