import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import "../Portada/Portada.scss";
const Portada = ({ datos = '' }) => {
  return (
    <section className="Portada">
      <div className="Imagen" />
      <div className="Contenido">
        <h1 className="h1">{datos.titulo}</h1>
        <p className="subtitulo">{datos.subtitulo}</p>
        <p className="presentacion_texto" dangerouslySetInnerHTML={{ __html: datos.descripcion }} />
        <span className="email" dangerouslySetInnerHTML={{ __html: datos.email }} />
        <ul className="redes">
          {datos.social?.map((item, index) => (
            <li key={index}>
              {item.tipo === 'linkedin' &&
                <a href={item.url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={brands('linkedin-in')} size="2x" /></a>
              }
              {item.tipo === 'twitter' &&
                <a href={item.url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={brands('twitter')} size="2x" /></a>
              }
              {item.tipo === 'instagram' &&
                <a href={item.url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={brands('instagram')} size="2x" /></a>
              }
              {item.tipo === 'youtube' &&
                <a href={item.url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={brands('youtube')} size="2x" /></a>
              }
            </li>
          ))}
        </ul>
      </div>
    </section>
    // <section className="sectionPortada">
    //   <div className="contenedorPortada">
    //     <div className="portada">
    //       <div className="espacio"></div>
    //       <div className="presentacion">
    //         <h1 className="h1">{datos.titulo}</h1>
    //         <p className="subtitulo">{datos.subtitulo}</p>
    //         <p className="presentacion_texto" dangerouslySetInnerHTML={{__html:datos.descripcion}}/>
    //         <span className="email" dangerouslySetInnerHTML={{__html:datos.email}}/>
    //         <ul className="redes">
    //           {datos.social?.map((item, index) =>(
    //             <li key={index}>
    //               {item.tipo === 'linkein' &&
    //                 <a href={item.url} target="_blank"><FontAwesomeIcon icon={brands('linkedin-in')} size="2x"/></a>
    //               }
    //               {item.tipo === 'twitter' &&
    //                 <a href={item.url} target="_blank"><FontAwesomeIcon icon={brands('twitter')} size="2x"/></a>
    //               }
    //               {item.tipo === 'instagram' &&
    //                 <a href={item.url} target="_blank"><FontAwesomeIcon icon={brands('instagram')} size="2x"/></a>
    //               }
    //               {item.tipo === 'yotube' &&
    //                 <a href={item.url} target="_blank"><FontAwesomeIcon icon={brands('youtube')} size="2x"/></a>
    //               }
    //             </li>
    //           ))} 
    //         </ul>
    //       </div>
    //     </div>
    //   </div>
    // </section>
  );
};
export default Portada;
