import React from "react";
import "./Otros.scss";
const Otros = ({ otros }) => {
  return (
    <>
      {otros?.map((item, index) => (
        <article className="contenido_otros" key={index}>
          <h3 className="h3">{item.titulo}</h3>
          <div className="grupo">
            {item.imagen != '' && 
              <img src={item.imagen} className="img" alt="" />
            }
            <div className="contenedor_texto">
              <p className="texto">{item.descripcion} </p>
              {item.url.map((enlace, index) => (
                <a href={enlace} key={index} target="_blank"> {enlace} </a>
              ))}
            </div>
          </div>
        </article>
      ))}
    </>
  );
};
export default Otros;
