import React from 'react';
import "../Pata/Pata.scss";
import img1 from "../../img/patita_gato.svg";
const Pata=()=> {
  return (
    <a href="#menu"className="contenedor_pata" title="Ir a menú">
      <img src={img1} className="patita" alt="patita de gato"/>
    </a> 
  );
}
export default Pata;