import "./App.scss";
import Main from "../Main";

function App() {
  return (
    <div className="app">
     <Main/>
    </div>
  );
}

export default App;
