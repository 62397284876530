import React from 'react';
import {useState} from 'react';
import "../Cautobombo/Cautobombo.scss";
import Articulo from "../Articulo";
import SegundaCarga from '../SegundaCarga';

const Cautobombo=({dat_autobombo = []})=> {
  let ordenado= dat_autobombo.sort(function (a, b) {
    if (a.fecha < b.fecha) {
      return 1;
    }
    if (a.fecha > b.fecha) {
      return -1;
    }
    return 0;
  });
  const limite = ordenado.slice(0,6);
  const [ver, setVer]=useState(false);

  return (
    <>
    {!ver &&
        <>
        <div className="btn" onClick={()=>setVer(true)}>See more</div>
        <div className="contenedor_autobombo">
          {
          limite?.map((item, index) =>(
            <Articulo key={index} item={item} />
          ))}
        </div>
        </>
    }
    {ver && 
    <>
      <div className="btn" onClick={()=>setVer(false)}>Go back</div>
      {/* <Filtro ordenado={ordenado}/>  */}
      <SegundaCarga ordenado={ordenado}/>
     </>
    }
    </>
  );
}
export default Cautobombo;