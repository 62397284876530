import React, { useCallback, useState, useEffect } from "react";
import "./carrousel.scss";
import useEmblaCarousel from "embla-carousel-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { PrevButton, NextButton } from "./EmblaCarouselButtons";

const EmblaCarousel = ({ proyectos = [] }) => {
  
  const [viewportRef, embla] = useEmblaCarousel({
    slidesToScroll: 1,
    skipSnaps: false,
    draggable: true,
    containScroll: "trimSnaps"
  });

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);
  useEffect(() => {
    if (!embla) return;
    embla.on("select", onSelect);
    onSelect();
  }, [embla, onSelect]);

  return (
    <>
      <div className="contenedor_carrousel">
        <div className="embla__prev">
          <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
        </div>
        <div className="embla__viewport" ref={viewportRef}>
          <div className="embla__container">
            {proyectos.map((item, index) => ( 
              <div className="embla__slide" key={index}>
                <div className="contenido_carrousel">
                  <div className="grupo_img">
                    <p className="titulo">{item.titulo}</p> 
                    <img className="img" src={item.imagen} alt={item.imagen} />
                  </div>
                  <div className="grupo_texto">
                    <p>{item.descripcion}</p>
                    <ul>
                      {item.url.map((valor, index) =>(
                        <li key={index}>
                          <a href={valor} className="enlace" target="_blank" rel="noreferrer">{valor}</a>
                        </li>
                      ))}  
                    </ul> 
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="embla__next">
          <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </div>
      </div>
    </>
  );
};
export default EmblaCarousel;