import React from "react";
import "../Cabecera/Cabecera.scss";
const Cabecera = ({ titulo, img, descripcion }) => {
return (
  <div className="cabecera">
    <div className="cabecera_titulo">
      <h2 className="h2">{titulo}</h2>
      <img src={img} className="cabecera_img" alt="" />
    </div>
    <div className="cabecera_texto">
      <p>{descripcion}</p>
    </div>
  </div>
  );
};
export default Cabecera;
