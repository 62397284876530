import { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';

import Portada from "../../components/Portada";
import Navegador from "../../components/Navegador";
import Autobombo from "../../components/Autobombo";
import Proyectos from "../../components/Proyectos";
import Pata from "../../components/Pata";
import Varios from "../../components/Varios";
import Footer from "../../components/Footer";

import "../App/App.scss";

function Main() {
  const [datos, setDatos] = useState({});
  const [autobombo, setAutobombo] = useState({});
  const [proyectos, setProyectos] = useState({});
  const [varios, setVarios] = useState({});
  const [metaData, setMetada] = useState ({
    title: '',
    name: '',
    descripcion: ''
  })
  useEffect(() => {
    fetch('data.json')
      .then(respuesta => respuesta.json())
      .then(data => {
        setDatos(data);
        const datAutobombo = data.secciones.filter(item => item.tipo === 'SELF-PRAISE');
        const datProyecto = data.secciones.filter(item => item.tipo === 'PROJECTS');
        const datVarios = data.secciones.filter(item => item.tipo === 'TRANSLATIONS&POETRY');

        setAutobombo(datAutobombo[0]);
        setProyectos(datProyecto[0]);
        setVarios(datVarios[0]);
        setMetada(data.metaData);

      })
  }, []);

  return (

    <>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="name" content={metaData.name} />
        <meta name="description" content={metaData.description} />
        <meta name="og:site_name" content={metaData.og_site_name} />
        <meta name="og:type" content={metaData.og_type} />
        <meta name="og:title" content={metaData.og_title} />
        <meta name="og:description" content={metaData.og_description} />
        <meta name="og:image" content={metaData.og_image_url} />
        <meta name="og:image:width" content={metaData.og_image_width} />
        <meta name="og:image:height" content={metaData.og_image_height} />
      </Helmet>

      <main className="main">
        <Pata />
        <Portada datos={datos.cabecera} />
        <Navegador menu1={"Self-praise"} menu2={"Projects"} menu3={"Misc"} />
        <Autobombo autobombo={autobombo} />
        <Proyectos proyectos={proyectos} />
        <Varios varios={varios} />
        <Footer enlaces={datos.cabecera} />
      </main>
    </>
  );
}
export default Main;