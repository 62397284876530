import React from 'react';
import {useState} from 'react';
import "../Articulo/Articulo.scss";
import Popup from "../Popup";
import useWindowsSize from "../../utiles/UseWindowsSize";
import {size} from "../../utiles/BreackPoint";

const Articulo=({item})=> {
  const[popup, setPopup]=useState(false);
  const esMovil= useWindowsSize().ancho < size.tablet ? true : false;
  return (
    <>
    <article className="articulo">
        <div className="autobombo_item">
        <img src={item.imagen} alt="" onClick={()=>(setPopup(true))}/>
          <div className="autobombo_titulo">
            {item.titulo}
          </div>
          <div className="autobombo_texto">
            <p>{item.descripcion}</p>
          </div>
          <ul className="autobombo_enlace">
            {item.url.map((valor, index) =>(
                <li key={index}>
                  <a href={valor} target="_blank" rel="noreferrer">{valor}</a>
                </li>
              ))}  
          </ul>
      </div>
    </article>
      { popup && esMovil &&
      <Popup setPopup={setPopup} item={item}/>} 
    </>
  );
}
export default Articulo;
