import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import "../Popup/Popup.scss";
const Popup =({setPopup, item})=>{
  return(
    <div className="popup">
      <div className="barra_popup">
        <div className="btn_cerrar">
        <FontAwesomeIcon icon={solid('circle-xmark')} size="xl" onClick={()=>(setPopup(false))} />
        </div>
        <div className="autobombo_titulo">
          {item.titulo}
        </div>
        <div className="autobombo_texto">
          {item.descripcion}
        </div>
        <ul className="autobombo_enlace">
          {item.url.map((valor, index) =>(
              <li key={index}>
                <a href={valor}>{valor}</a>
              </li>
            ))}  
        </ul>
      </div>
    </div>
  )
};
export default Popup;