import React from 'react';
import "./Footer.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { brands} from '@fortawesome/fontawesome-svg-core/import.macro' 
const Footer=({enlaces=''})=> {
  return (
    <footer className="footer">
    <span className="email" dangerouslySetInnerHTML={{__html:enlaces.email}}/>
      <ul className="redes">
        {enlaces.social?.map((item, index) =>(
          <li key={index}>
            {item.tipo === 'linkedin' &&
              <a href={item.url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={brands('linkedin-in')} size="2x"/></a>
            }
            {item.tipo === 'twitter' &&
              <a href={item.url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={brands('twitter')} size="2x"/></a>
            }
            {item.tipo === 'instagram' &&
              <a href={item.url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={brands('instagram')} size="2x"/></a>
            }
            {item.tipo === 'youtube' &&
              <a href={item.url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={brands('youtube')} size="2x"/></a>
            }
          </li>
          ))} 
        </ul>
      <span className="copiright">Copyright © 2022 rafatux.com. Todos los derechos reservados.</span>
    </footer>
  );
}
export default Footer;