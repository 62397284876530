import React from "react";
import "../Proyectos/Proyectos.scss";
import Cabecera from "../Cabecera";
import imgproyectos from "../../img/proyectos.svg";
import Carrousel from "../Carrousel";
const Proyectos = ({ proyectos }) => {
  return (
    <section className="section proyectos" id="proyectos">
      <Cabecera titulo="Projects" img={imgproyectos} descripcion={proyectos.descripcion} />
      <div className="contenedor_proyectos">
        {proyectos.items && (
          <Carrousel proyectos={proyectos.items}/>
        )}
      </div>
    </section>
  );
};
export default Proyectos;
